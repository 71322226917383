var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "width": "".concat(_vm.width, "px"),
      "height": "".concat(_vm.height, "px"),
      "viewBox": "0 0 20 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.5925 2.02719C12.6803 1.04234 11.4062 0.5 9.99996 0.5C8.58621 0.5 7.30793 1.03906 6.39996 2.01781C5.48215 3.00734 5.03496 4.35219 5.13996 5.80437C5.34809 8.66937 7.52824 11 9.99996 11C12.4717 11 14.6481 8.66984 14.8595 5.80531C14.9659 4.36625 14.5159 3.02422 13.5925 2.02719V2.02719ZM18.25 21.5H1.74996C1.53399 21.5028 1.32011 21.4574 1.12388 21.3672C0.927654 21.2769 0.754011 21.1441 0.615588 20.9783C0.3109 20.6141 0.188088 20.1167 0.279025 19.6137C0.67465 17.4191 1.90934 15.5755 3.84996 14.2812C5.57403 13.1323 7.75793 12.5 9.99996 12.5C12.242 12.5 14.4259 13.1328 16.15 14.2812C18.0906 15.575 19.3253 17.4186 19.7209 19.6133C19.8118 20.1162 19.689 20.6136 19.3843 20.9778C19.246 21.1437 19.0723 21.2766 18.8761 21.367C18.6799 21.4573 18.466 21.5028 18.25 21.5V21.5Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }